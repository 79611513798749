<template>
  <div>
    <div class="row mt-2 mx-2">
      <div class="col-lg-3 col-md-4 col-sm-12" v-for="item in system_cards" :key="item.title">
        <v-hover v-slot="{ hover }">
              <v-card
                :elevation="hover ? 5 : 2"
                :class="{ up: hover }"
                outlined
                link
                color="rgba(255,255,255,0)"
                :to="item.link"
              >
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="text-overline mb-4">{{ item.title }}</div>
                    <v-list-item-title
                      v-if="item.title == 'ACTIVE PUBLIC SURVEYS'"
                      class="text-h5 mb-1"
                      >{{ institutionSummary.activePublicSurveys }}</v-list-item-title
                    >
                    <v-list-item-title
                      v-else-if="item.title == 'INACTIVE PUBLIC SURVEYS'"
                      class="text-h5 mb-1"
                      >{{ institutionSummary.inactivePublicSurveys }}</v-list-item-title
                    >
                    <v-list-item-title
                      v-else-if="item.title == 'ACTIVE PRIVATE SURVEYS'"
                      class="text-h5 mb-1"
                      >{{ institutionSummary.activePrivateSurveys }}</v-list-item-title
                    >
                    <v-list-item-title
                      v-else-if="item.title == 'INACTIVE PRIVATE SURVEYS'"
                      class="text-h5 mb-1"
                      >{{ institutionSummary.intactivePrivateSurveys }}</v-list-item-title
                    >
                  </v-list-item-content>
  
                  <v-list-item-avatar tile size="80" color="white">
                    <v-icon
                      style="font-size: 72px"
                      dark
                      large
                      :color="item.color"
                    >
                      {{ item.icon }}
                    </v-icon>
                  </v-list-item-avatar>
                </v-list-item>
              </v-card>
            </v-hover>
      </div>
    </div>
  </div>
</template>

<script>


import { mapGetters } from "vuex";

export default {
  name: "UserDashboard",
  components: {
  },

  data () {
    return {
    system_cards: [
      {
        title: "ACTIVE PUBLIC SURVEYS",
        link: "/surveys",
        icon: "mdi-ballot",
        color: "#db9b32",
      },
        {
          title: "INACTIVE PUBLIC SURVEYS",
          link: "",
          icon: "mdi-ballot",
          color: "#1a568a",
        },
      {
        title: "ACTIVE PRIVATE SURVEYS",
        link: "/surveys",
        icon: "mdi-ballot",
        color: "#11662e",
      },
      {
        title: "INACTIVE PRIVATE SURVEYS",
        link: "/surveys",
        icon: "mdi-ballot",
        color: "#11662e",
      },
    ],
    options: {
      title: {
        text: "public vs private surveys for this month",
        align: "center",
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: "24px",
          fontWeight: "bold",
          fontFamily: undefined,
          color: "#448ee4",
        },
      },
      chart: {
        id: "vuechart-example",
      },
      xaxis: {
        categories: ['public surveys' , 'private surveys'],
      },
    },
    series: [
      {
        name: "series-1",
        data: [],
      },
    ],
    options2: {
      title: {
        text: "public vs private surveys for this month",
        align: "center",
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: "24px",
          fontWeight: "bold",
          fontFamily: undefined,
          color: "#448ee4",
        },
      },
      chart: {
        id: "vuechart-example",
      },
      xaxis: {
        categories: ['active surveys' , 'inactive surveys'],
      },
    },
    series2: [
      {
        name: "series-1",
        data: [],
      },
    ],
    testKey: 1
  }},

  methods: {},

  computed: {
    ...mapGetters({
      permissions: "getUserPermissions",
      institutionSummary: "getInstAdminDashboardSummary",
      institutionAdminMonthlyVisualizations: "getInstitutionAdminMonthlyVisualizations"
    }),
  },
  async mounted() {
    await this.$store.dispatch("fetchSystemSummary");
    await this.$store.dispatch('fetchInstAdminDashboardSummary');
    await this.$store.dispatch('fetchMonthlyInstAdminVisualizations');

    this.series[0].data = [this.$store.getters.getInstitutionAdminMonthlyVisualizations.publicSurveyCounts, 
                            this.$store.getters.getInstitutionAdminMonthlyVisualizations.privateSurveyCounts]
    this.$refs.myChart.refresh();

    this.series2[0].data = [this.$store.getters.getInstitutionAdminMonthlyVisualizations.activeSurveys, 
                            this.$store.getters.getInstitutionAdminMonthlyVisualizations.inactiveSurveys]
    this.$refs.chart2.refresh()
  },
  beforeMount(){
    
  },
  
  
};
</script>

<style scoped>
</style>